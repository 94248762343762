export const getFormatedTimeFromSeconds = (segundos) => {
    const horas = Math.floor(segundos / 3600);
    const minutos = Math.floor((segundos % 3600) / 60);
    
    const horasFormatadas = horas > 0 ? `${horas}h` : '';
    const minutosFormatados = minutos > 0 ? `${minutos}min` : '';

    return horasFormatadas + (horasFormatadas && minutosFormatados ? ' ' : '') + minutosFormatados;
}


export function getVideoDurationFromSeconds(segundos) {
    let horas = Math.floor(segundos / 3600);
    let minutos = Math.floor((segundos % 3600) / 60);
    let segundosRestantes = (segundos % 60);

    if(horas)
        horas = horas.toFixed(0)
    if(minutos)
        minutos= minutos.toFixed(0)
    if(segundosRestantes)
        segundosRestantes = segundosRestantes.toFixed(0)

    const formatoHora = `${horas ? horas.toString().padStart(2, '0') + ':' : ''}${minutos.toString().padStart(2, '0')}:${segundosRestantes.toString().padStart(2, '0')}`;

    return formatoHora;
}
