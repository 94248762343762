import { Reels } from '@sayings/react-reels';
import '@sayings/react-reels/dist/index.css'

import "./style.css"
import { useEffect, useState } from 'react';
import MembersAreaService from '../../services/MemberArea.service';
import { useParams, useHistory } from "react-router-dom";
import { Icon } from 'rsuite';


export const storiesMock = [
    {
      id: 1,
      reelInfo: {
        thumb: "https://templates.simplified.co/usetldr/971783/thumb/06fbbd21-4ba6-4ba5-ad38-a21aaa8ba781.jpg",
        url: 'https://vz-0b9746f1-234.b-cdn.net/876ae1fa-50ed-44b2-8150-02869d281135/playlist.m3u8',
        type: 'video/mp4',
        description: 'Lorem ipsum dolor sit amet?',
        postedBy: {
          avatar: 'https://avatars.dicebear.com/api/big-smile/trijit.svg?mood[]=happy&background=%23efefef',
          name: 'Trijit Goswami'
        },
        likes: {
          count: 759878
        },
        // dislikes: {
        //   count: 124
        // },
        // comments: {
        //   count: 10089.345
        // },
        // shares: {
        //   count: 299792458
        // }
      },
      rightMenu: {
        options: [
          {
            id: 1,
            label: 'Option 1',
            value: 'option-1'
          },
          {
            id: 2,
            label: 'Option 2',
            value: 'option-2'
          },
          {
            id: 3,
            label: 'Option 3',
            value: 'option-3'
          }
        ]
      }
    },
    {
      id: 2,
      reelInfo: {
        thumb: "https://templates.simplified.co/usetldr/971783/thumb/06fbbd21-4ba6-4ba5-ad38-a21aaa8ba781.jpg",
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4',
        type: 'video/mp4',
        description: 'Lorem ipsum dolor sit amet?',
        postedBy: {
          avatar: 'https://avatars.dicebear.com/api/big-smile/sourav.svg?mood[]=happy&background=%23efefef',
          name: 'Sourav Halder'
        },
        likes: {
          count: 559878
        },
        // dislikes: {
        //   count: 12
        // },
        // comments: {
        //   count: 10089.345
        // },
        // shares: {
        //   count: 299792458
        // }
      },
      rightMenu: {
        options: [
          {
            id: 1,
            label: 'Option 1',
            value: 'option-1'
          },
          {
            id: 2,
            label: 'Option 2',
            value: 'option-2'
          },
          {
            id: 3,
            label: 'Option 3',
            value: 'option-3'
          }
        ]
      }
    },
    {
      id: 3,
      reelInfo: {
        thumb: "https://s2-g1.glbimg.com/MH2tpyTeafIhqo95MAJWgJR9c54=/304x540/top/smart/filters:max_age(3600)/https://s01.video.glbimg.com/deo/vi/52/57/11845752",
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerBlazes.mp4',
        type: 'video/mp4',
        description: 'Lorem ipsum dolor sit amet?',
        postedBy: {
          avatar: 'https://avatars.dicebear.com/api/big-smile/sanjib.svg?mood[]=happy&background=%23efefef',
          name: 'Sanjib Kumar Mandal'
        },
        likes: {
          count: 123
        },
        // dislikes: {
        //   count: 0
        // },
        // comments: {
        //   count: 14
        // },
        // shares: {
        //   count: 0
        // }
      },
      rightMenu: {
        options: [
          {
            id: 1,
            label: 'Option 1',
            value: 'option-1'
          },
          {
            id: 2,
            label: 'Option 2',
            value: 'option-2'
          },
          {
            id: 3,
            label: 'Option 3',
            value: 'option-3'
          }
        ]
      }
    },
  ]

  const reelMetaInfo = {
    videoDimensions: {
      height: 580,
      width: 330,
    },
    backGroundColor: '#000000',
    borderRadius: 10
  }

  setInterval(()=>{
    const video = document.querySelector('.swiper-slide-active > div > div > video')
    const url_video = document.querySelector('.swiper-slide-active > div > div > video > source[type="video/mp4"]')?.attributes?.src?.value
    // console.log(url_video)
    if(url_video){
      if (window.Hls.isSupported()) {
        var hls = new window.Hls();

        if(window.active_media !== url_video){
          window.active_media = url_video
          hls.loadSource(url_video);
          hls.attachMedia(video);
        }else{
        }
      } /*else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url_video;
      }*/
    }
  }, 500)

const ReelsPage = (props) => {

  const history = useHistory();


  let { reel_id } = useParams();

  const [schoolData, setSchoolData] = useState({
    shortVideos: []
  })


  const getSchoolData = async () => {
    const response = await new MembersAreaService().get(`student/school/${window.location.hostname}`)
    if(response){
      setSchoolData(response)
    }
  }

  useEffect(()=>{
    getSchoolData()

    window.active_media = ""
  }, [])


  // useEffect(()=>{
      

  // })

  const orderById = (a, b) => {
    const id = reel_id
    if(id){
      if (a._id === id) {
        return -1; 
      }
    
      if (b._id === id) {
        return 1; 
      }
    }
  
    return a.updatedAt.localeCompare(b.updatedAt);
  };


  const getShortVideoThumbnailURL = (video_id) => {
    return `${process.env.REACT_APP_API_URL}/members/short_video/thumbnail/preview/${video_id}`
  }


  return <>
    <div id="reels-container">
    <div onClick={() => history.push('/')} className="back-button-reels" style={{position: 'absolute', color: 'white', zIndex: '99999999', display: 'flex', alignItems: 'center'}}><Icon icon="back-arrow" size='2x' style={{marginRight: '15px'}} /> Voltar</div>
      {

        schoolData.shortVideos.length ? <Reels 
            reels={schoolData?.shortVideos?.sort(orderById).map(shortVideo => {
              const thumb_url = getShortVideoThumbnailURL(shortVideo?.videoSource?.videoId)
              return {
                id: shortVideo._id,
                reelInfo: {
                  thumb: thumb_url,
                  url: `https://vz-0b9746f1-234.b-cdn.net/${shortVideo?.videoSource?.videoId}/playlist.m3u8`,
                  type: 'video/mp4',
                  description: shortVideo?.description,
                  // postedBy: {
                  //   avatar: 'https://avatars.dicebear.com/api/big-smile/trijit.svg?mood[]=happy&background=%23efefef',
                  //   name: 'Trijit Goswami'
                  // },
                  likes: {
                    count: shortVideo?.likes
                  },
                  // dislikes: {
                  //   count: 124
                  // },
                  // comments: {
                  //   count: 10089.345
                  // },
                  // shares: {
                  //   count: 299792458
                  // }
                },
                // rightMenu: {
                //   options: [
                //     {
                //       id: 1,
                //       label: 'Option 1',
                //       value: 'option-1'
                //     },
                //     {
                //       id: 2,
                //       label: 'Option 2',
                //       value: 'option-2'
                //     },
                //     {
                //       id: 3,
                //       label: 'Option 3',
                //       value: 'option-3'
                //     }
                //   ]
                // }
              }
            })  || null} 
            reelMetaInfo={reelMetaInfo}
            onMenuItemClicked={(event) => console.log("From Parent", event)}
            onLikeClicked={(reel) => console.log("From Parent", reel)}
            onDislikeClicked={(reel) => console.log("From Parent", reel)}
            onCommentClicked={(reel) => console.log("From Parent", reel)}
            onShareClicked={(reel) => console.log("From Parent", reel)}
            onAvatarClicked={(reel) => console.log("From Parent", reel)}
            duration={1000} 
      /> : null
      }
      
    </div>
  </>
};
    
export default ReelsPage;