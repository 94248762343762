import React, { useEffect, useRef, useState } from "react";
import { Col, Icon, Row } from "rsuite";
import WaveSurfer from "wavesurfer.js";

const AudioPlayer = ({ audioUrl, callback }) => {
const waveformRef = useRef(null);
const playPauseButtonRef = useRef(null);
const volumeInputRef = useRef(null);
const [isPlaying, setIsPlaying] = useState(false);

useEffect(() => {
    const root = document.documentElement;
    const primaryColor = getComputedStyle(root).getPropertyValue('--primary-color');
    const cursorColor = getComputedStyle(root).getPropertyValue(' --primary-color-hover-extra');


    const wavesurfer = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: primaryColor,
        progressColor: primaryColor,
        cursorColor: cursorColor,
        barWidth: 2,
        barRadius: 3,
        responsive: true,
        height: 70,
        hideScrollbar: true,
    });

    wavesurfer.load(audioUrl);
    wavesurfer.setVolume(1);

    const togglePlayPause = () => {
        if(wavesurfer.isPlaying()){
            wavesurfer.pause()
            setIsPlaying(false)
        }else{
            wavesurfer.play()
            setIsPlaying(true)
        }
    }

    if(callback)
        callback(true, null)

    wavesurfer.on('finish', ()=>{
        if(callback)
            callback(true, true)
    })

    const volumeChangeHandler = (event) => {
        wavesurfer.setVolume(event.target.value);
    };



    playPauseButtonRef.current.addEventListener("click", togglePlayPause);
    volumeInputRef.current.addEventListener("input", volumeChangeHandler);

    return () => {
        wavesurfer.destroy();
    //   playPauseButtonRef.current.removeEventListener("click", togglePlayPause);
    //   volumeInputRef.current.removeEventListener("input", volumeChangeHandler);
    };
}, [audioUrl]);

return (
<Row>
    <Col lg={6}>
    <Row>
        <Col lg={24} style={{marginTop: '10px', display: 'flex', justifyContent: 'center'}}>
            <button 
                className="rs-btn rs-btn-default rs-btn-icon rs-btn-icon-placement-left rs-btn-icon-circle rs-btn-primary"
                ref={playPauseButtonRef}
            >
                {isPlaying ? <Icon icon="pause" /> : <Icon icon="play" />}
            </button>
        </Col>
    </Row>
    <Row>
        <Col lg={24} style={{marginTop: '15px', display: 'flex', justifyContent: 'center'}}>
            <input
                type="range"
                min="0"
                max="1"
                step="0.01"
                defaultValue="1"
                ref={volumeInputRef}
            />
        </Col>
    </Row>
    </Col>
    <Col lg={18}>
    <div ref={waveformRef}></div>
    </Col>
</Row>
);
};

export default AudioPlayer;