import 'rsuite/dist/styles/rsuite-default.css';
import '../index.css';
import '../App.css';
import '../spinner.css';
// import '../default-theme.css';

const DefaultTemplate = (props) => {
    return props.children;
};
    
export default DefaultTemplate;