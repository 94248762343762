
import { useState, useEffect } from 'react'
import { Container, Header, Content, Row, Col, Button, Icon, IconButton, Timeline, Panel, Loader, Grid, Radio } from 'rsuite';
import { useParams, useHistory } from "react-router-dom";

import MembersAreaService from '../../services/MemberArea.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import hd from '../../assets/svg/hd.svg'

import './style.css'
import { getFormatedTimeFromSeconds } from '../../utils/formatSeconds.utils';
import AudioPlayer from '../../components/AudioPlayer.component';

const RichTextClassPage = () => {
    const { courseId } = useParams();
    const history = useHistory()

    const [cover, setCover] = useState({})

    const [metadata, setMetadata] = useState({
        course: {},
        modules: {}
    })

    const [courseProgress, setCourseProgress] = useState({
        courseDonePercent: 0,
        courseVisitedPercent: 0,
        courseTotalChildren: [],
    })

    const [lastSessionProgress, setLastSessionProgress] = useState(null)

    const loadCourseContent = async () => {
        const response = await new MembersAreaService().get(`student/course/${courseId}`)
        
        if(response || !response?.error){
            console.log('response', response)
            setMetadata(response)

            const isMobile = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i)

            if(!isMobile){
                if(response?.course?.courseImageDesktop){
                    setCover(response?.course?.courseImageDesktop?.url)
                }
            }else{
                if(response?.course?.courseImageMobile){
                    setCover(response?.course?.courseImageMobile?.url)
                }else if(response?.course?.courseImageDesktop){
                    setCover(response?.course?.courseImageDesktop?.url)
                }
            }
        }
    }
    const loadCourseProgress = async () => {
        const response = await new MembersAreaService().get(`student/get/course/progress/${courseId}`)
        if(response || !response?.error){
            setCourseProgress(response)
        }
    }
    const loadLastSessionProgress = async () => {
        const response = await new MembersAreaService().get(`student/get/progress/course/${courseId}`)
        if(response || !response?.error){
            setLastSessionProgress(response)
        }
    }

    const getVideoPreview = (video_id, lesson_progress) => {
        if(!video_id){
            return <div class="thumbnail-preview-mini"><small>Prévia não disponível</small></div>
        }
    
        return <div>
            <div class="thumbnail-preview-mini" 
                style={{backgroundImage: `URL('${process.env.REACT_APP_API_URL}/members/lesson/thumbnail/preview/${video_id}')`, backgroundSize: 'cover'}}
            >
                <Icon icon='play-circle' size='2x' className="play-button-circle" />
                
            </div>
            <div style={{position: 'relative', width: `${(lesson_progress?.watchTime * 100) / lesson_progress?.totalTime || 0}%`,  left: '0px', bottom: '0px'}}>
                    <div style={{backgroundColor: 'red', height: '5px', borderBottomLeftRadius: '3px'}}></div>
            </div>
        </div>
        
    }

    const openClassRoom = (moduleId, lessonId) => {
        if(lessonId){
            history.push(`/classroom/${courseId}/${moduleId}/${lessonId}`)
        }else{
            history.push(`/classroom/${courseId}/${moduleId}`)
        }
    }

    const resumeClassRoom = () => {
        if(lastSessionProgress){
            history.push(`/classroom/${courseId}/${lastSessionProgress?.module}/${lastSessionProgress?.lesson}`)
        }else{
            history.push(`/classroom/${courseId}/${metadata?.modules[0]?._id}`)
        }
    }

    const getContentCount = (payload) => {
        let text = ""

        const videosCount = payload.filter(item => item?.lesson?.type === "video").length
        const documentsCount = payload.filter(item => item?.lesson?.type === "document").length
        const audioCount = payload.filter(item => item?.lesson?.type === "audio").length
        const exerciceCount = payload.filter(item => item?.exercice).length

        if(videosCount){
            text = `${text} ${videosCount} ${videosCount > 1 ? 'videos' : 'video'},`
        }
        if(documentsCount){
            text = `${text}  ${documentsCount} ${documentsCount > 1 ? 'documentos' : 'documento'},`
        }
        if(audioCount){
            text = `${text} ${audioCount} ${audioCount > 1 ? 'áudios' : 'áudio'},`
        }
        if(exerciceCount){
            text = `${text} ${exerciceCount} ${exerciceCount > 1 ? 'atividades' : 'atividade'}`
        }
        
        //4 vídeos, 3 exercícios, 1 prova
        return  text
    }

    const getTotalCourseDuration = () => {
        let durationSeconds = 0

        if(metadata.modules?.length){
            metadata.modules.forEach((module) => {
                if(module?.children?.length){
                    module.children.forEach((child)=>{
                        switch(child.onModel){
                            case "Lesson":
                                const type = child.lesson.type 
                                if(type === "video"){
                                    const videoDuration = child.lesson.videoSource.duration
                                    durationSeconds += videoDuration || 1800  // 30 minutes

                                }
                                if(type === "document"){
                                    durationSeconds += 1800 // 30 minutes
                                }

                                if(type === "audio"){
                                    const audioDuration = child.lesson.audioSource.duration
                                    durationSeconds += audioDuration || 600  // 10 minutes
                                }
                            break;
                            case "Exercice":
                                const exercice = child.exercice

                                if(exercice?.canRepeat){
                                    durationSeconds += 600
                                }else {
                                    durationSeconds += 1800
                                }
                            break;
                            default:
                            break;
                        }
                    })
                }
            })

        }

        return getFormatedTimeFromSeconds(durationSeconds)
    }

    useEffect(()=>{
        // loadCourseContent()
        // loadCourseProgress()
        // loadLastSessionProgress()
    },[])

    return  <>
        {/* header */}
        <Row>
            <div class="container" style={cover ? {backgroundImage: `url('https://picsum.photos/800/200')` } : {}}>
                <div class="overlay"></div>
                <div class="cover-content">
                    <Row>
                        <Col lg={24}> 
                            <h2>Understanding Accents in English</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={24}>
                            <h2>{metadata?.course?.name}</h2>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '2.5em', display: 'flex', flexDirection: 'row', alignItems: 'flex-end'}}>
                        {/* <div style={{marginRight: '25px'}}> */}
                            {/* <Button className="course-cta-button" appearance='default' onClick={()=>resumeClassRoom()}><Icon icon="play"/> <span style={{marginLeft: '10px', fontWeight: 'bold'}}>{lastSessionProgress ? 'Continuar' : 'Assistir'}</span></Button> */}
                        {/* </div> */}
                        {/* <div lg={2}>
                            <Button className="course-cta-button" appearance='default' color="red" style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center'}}>
                                <div style={{'font-size': '0.6rem', marginRight: '10px', paddingBottom: '4px'}}>⬤</div>
                                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                                    <small className="button-small-text" style={{fontWeight: 700}}>Aula ao vivo </small>
                                    <small className="button-small-text">16/03/2024 - 13:04 </small> 
                                    <small className="button-small-text">Clique para assistir </small>
                                </div>
                            </Button>
                        </div> */}
                        <div><IconButton className="like-button" appearance='ghost' circle icon={<Icon icon='thumbs-o-up' />} /></div>
                        <div style={{marginLeft: '10px'}}><IconButton className="like-button" appearance='ghost' circle icon={<Icon icon='thumbs-o-down' />} /></div>
                    </Row>
                </div>
            </div>
        </Row>
        {/*  end header */}
        {/* content */}
        <Container style={{padding: '0 4.5%'}}>
            <Row>
                <Col lg={18}>
                    <Row>
                        <Col lg={24}>
                            <div className='course-metadata'>
                                <span style={{fontWeight: 'bolder'}}>Carga Horária Aprox.</span>
                                <span style={{margin: '0 10px'}}>40 min</span>
                                <img src={hd} alt="High Definition" width="25px" />
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px'}}>
                        <Col lg={7}>
                            <div style={{position: 'absolute',  minWidth: '3%', width: '100%', left: '5px', bottom: '0px', display: 'flex', alignItems: 'center'}}>
                                <div className="progress-bar-theme" style={{minWidth: '4%', width: `${courseProgress.courseDonePercent.toFixed()}%`, height: '5px', borderRadius: '3px'}}></div> <span style={{color: '#ffffffab', fontWeight: '600', marginLeft: '15px', fontSize: '0.7rem'}}>{courseProgress.courseDonePercent.toFixed()}%</span>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col lg={7} style={{fontWeight: 'bold'}}>Professores: </Col>
                        <Col lg={17}><span className="text-light">Celso, Washington</span></Col>
                    </Row>
                    <Row style={{marginTop: '25px'}}>
                        <Col lg={7} style={{fontWeight: 'bold'}}>Módulo: </Col>
                        <Col lg={17}><span className="text-light">Upper Intermediate</span></Col>
                    </Row>

                    {/* <Row style={{marginTop: '25px'}}>
                        <Col lg={24}> <Button appearance='ghost' color="green"><Icon icon="mortar-board" /> Obter meu Certificado</Button></Col>
                    </Row> */}
                </Col>
            </Row>


            <Row style={{marginTop: '55px'}}>
                <Col lg={18}>
                    <Row>
                        <Col><p>Welcome, class! Today, we'll dive into the fascinating world of accents in English. Accents add color and diversity to the language, reflecting the rich tapestry of cultures that speak it. Let's explore the key aspects together.</p></Col>
                    </Row>
                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <img src="https://www.mosalingua.com/en/files/2020/09/english-vs-american-accent-1.jpg" />
                        </Col>
                    </Row>
                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h6>I. What is an Accent?</h6>
                            <ul>
                                <li>Accents are distinctive ways of pronouncing words, influenced by regional, cultural, or linguistic backgrounds.</li>
                                <li>They can encompass variations in intonation, rhythm, and pronunciation of sounds.</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h6>II. Types of Accents:</h6>
                            <ul>
                                <li><strong>Regional Accents:</strong> These are associated with specific geographical areas, like British, American, Australian, etc.</li>
                                <li><strong>Non-Native Accents:</strong> Influenced by a speaker's native language, these can lead to unique pronunciation patterns.</li>
                                <li><strong>Social Accents:</strong> Linked to factors like education, socioeconomic status, and profession.</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h6>III. Understanding Pronunciation:</h6>
                            <ul>
                                <li><strong>Vowel Sounds:</strong> Different accents emphasize particular vowel sounds, affecting words like "cot" and "caught".</li>
                                <li><strong>Consonant Sounds:</strong> Variations in sounds like "r", "th", and "t" can create distinct accents.</li>
                                <li><strong>Stress and Intonation:</strong> The rhythm and emphasis placed on words can vary significantly across accents.</li>
                            </ul>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h4>Watch the video</h4>
                        </Col>
                    </Row>


                    <Row style={{marginTop: '25px'}}>
                        <Col style={{display: 'flex', justifyContent: 'start'}}>
                            <iframe src="https://iframe.mediadelivery.net/embed/127095/ed232725-a379-458c-bad1-2979c5b8de57?autoplay=true&loop=false&muted=false&preload=false" allow="accelerometer;gyroscope;autoplay;encrypted-media;picture-in-picture;" loading="lazy" style={{
                        border: '0',
                        position: 'relative',
                        top: '0',
                        height: '450px',
                        width: '100%'
                        }} allowfullscreen="true"></iframe>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <p style={{fontWeight: 'bold'}}>About the video:</p>
                            <p>Accents are not a sign of language proficiency. Embrace the beauty of linguistic diversity and understand that clarity is the ultimate goal.</p>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h4>Test your knowledge</h4>
                            <p>Listen to the audio and select the subject spoken in the text from the alternatives</p>
                            <Row style={{marginTop: '44px', marginBottom: '15px', display: 'flex', justifyContent: 'start'}}>
                                <Col lg={12}><AudioPlayer audioUrl={`${process.env.REACT_APP_API_URL}/members/lesson/audio/1690827261560-happy-birthday-in-english-male-15023.mp3`} /></Col>
                            </Row>
                            <Row style={{marginTop: '35px', display: 'flex', justifyContent: 'start'}}>
                                <Col lg={18}>
                                    <Row>
                                        <Col lg={24}><Radio> They have no impact on understanding spoken English.</Radio></Col>
                                    </Row>
                                    <Row>
                                        <Col lg={24}><Radio> Accents can indicate a speaker's regional or cultural background.</Radio></Col>
                                    </Row>
                                    <Row>
                                        <Col lg={24}><Radio> Accents are only important for written communication..</Radio></Col>
                                    </Row>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h6>V. Accents in Pop Culture:</h6>
                            <p>Explore how accents are used in movies, TV shows, and music to add depth to characters and settings.</p>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h6>VI. Overcoming Communication Barriers:</h6>
                            <p>Tips for understanding and being understood when encountering different accents.</p>
                        </Col>
                    </Row>

                    <Row style={{marginTop: '25px'}}>
                        <Col>
                            <h4>Conclusion</h4>
                            <p><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT-daiU7rmUMFMrNABLDjWQryjBw1RQ5W2sgA&usqp=CAU" /></p>
                            <p>Accents are a natural and vibrant part of English. Embracing them opens doors to better global communication. Keep practicing, stay curious, and celebrate the diversity of language!</p>
                            <p><em>Note: For a deeper understanding, explore different accents through movies, podcasts, and conversations with native speakers.</em></p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: '25px', height: '200px'}}></Row>
            

        </Container>
        {/* end content */}
    </>
}

export default RichTextClassPage;
